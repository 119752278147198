// spinner 1
.spinner {
	margin: 10px auto;
	width: 40px;
	height: 40px;
	position: relative;
	text-align: center;

	-webkit-animation: sk-rotate 2s infinite linear;
	animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
	width: 60%;
	height: 60%;
	display: inline-block;
	position: absolute;
	top: 0;
	background-color: #333;
	border-radius: 100%;

	-webkit-animation: sk-bounce 2s infinite ease-in-out;
	animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
	top: auto;
	bottom: 0;
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes sk-rotate {
	100% {
		transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
	}
}

@-webkit-keyframes sk-bounce {
	0%,
	100% {
		-webkit-transform: scale(0);
	}
	50% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bounce {
	0%,
	100% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}
	50% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}

// spinner 2

.sk-chase {
	width: 40px;
	height: 40px;
	position: relative;
	animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
	content: "";
	display: block;
	width: 25%;
	height: 25%;
	background-color: #fff;
	border-radius: 100%;
	animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
	animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
	animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
	animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
	animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
	animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
	animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
	animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
	animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
	animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
	animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
	animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
	animation-delay: -0.6s;
}

@keyframes sk-chase {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes sk-chase-dot {
	80%,
	100% {
		transform: rotate(360deg);
	}
}

@keyframes sk-chase-dot-before {
	50% {
		transform: scale(0.4);
	}
	100%,
	0% {
		transform: scale(1);
	}
}
