@import "../shared/colors";

@mixin activeNav {
  background-color: #ddd;
  color: $primary-color !important;
  font-weight: bold;
}

//mobile first design

.primary-nav {
  z-index: 100;
  position: sticky;
  top: 0;
  background-color: white !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 50px;

  padding-right: 4vw;
  padding-left: 4vw;

  & .nav-logo {
    background-color: whitesmoke !important;
    display: inline;
    padding-left: 10px;
  }

  & .nav-links {
    width: 80vw;

    & a {
      padding: 9px;
      margin-right: 5px;

      & .bi {
        margin-right: 0px;
      }

      color: #555;

      &:hover {
        @include activeNav();
      }
    }
  }
}

.active-nav {
  @include activeNav();
  border-radius: 5px;
}

// landscape upwards (desktop, tablet, wide screen etc)
@media screen and (min-width: 600px) {
  .primary-nav {
    padding-right: 9vw;
    padding-left: 9vw;

    & .nav-links {
      width: 70vw;

      & a {
        padding: 17px 17px 14px 17px;
        color: #555;
        margin-right: 0px;

        & .bi {
          margin-right: 5px;
        }
      }
    }
  }

  .active-nav {
    @include activeNav();
    border-radius: 0px;
  }
}
