.app-layout .layout-outlet {
  min-height: 100vh !important;
  min-width: 100vw !important;
  background-color: #ddd !important;
}

.spinner {
  margin: 10px auto;
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;
  animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #333;
  border-radius: 100%;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  animation-delay: -1s;
}
@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}

.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}

.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}

.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot {
  80%, 100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%, 0% {
    transform: scale(1);
  }
}
.bokx-btn {
  padding: 8px 20px;
  font-size: 1.1em;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid #0965c2;
  background-color: #0d7aa8;
  transition: all 0.1s linear;
}
.bokx-btn:hover {
  background-color: #5bcdfe !important;
}

.btn-prim {
  padding: 8px 20px;
  font-size: 1.1em;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid #0965c2;
  background-color: #0d7aa8;
  transition: all 0.1s linear;
  background-color: #0965c2 !important;
  color: whitesmoke;
}
.btn-prim:hover {
  color: whitesmoke;
  background-color: #012f43 !important;
  border: 1px solid #012f43;
}

.bokx-color {
  color: #0965c2 !important;
}

.bokx-color-dark {
  color: #012f43 !important;
}

.bokx-bg-secondary {
  background-color: #f0f2f5;
}

.bokx-bg {
  background-color: #0965c2 !important;
  transition: all 0.2s linear;
}
.bokx-bg:hover {
  background-color: #012f43 !important;
}

.bokx-border {
  border: 1px solid #0965c2 !important;
}

.hover-hand:hover {
  cursor: pointer !important;
  background-color: #e8e8e9 !important;
}

.hover:hover {
  cursor: pointer !important;
}

.page-title {
  color: #0965c2 !important;
  font-weight: bold;
  text-transform: uppercase;
}

.ant-steps-item-icon,
.ant-steps-icon {
  color: whitesmoke !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #0965c2 !important;
}

.app-tile {
  border: 1px solid #999;
  border-radius: 5px;
}
.app-tile * {
  color: #666 !important;
}

.summary-card {
  border: 1px solid whitesmoke;
  background-color: whitesmoke;
  border-radius: 5px;
  transition: all 0.25s linear;
}
.summary-card .data {
  color: #0965c2 !important;
}
.summary-card .card-label {
  color: #666 !important;
  line-height: 18px !important;
}
.summary-card .stock-alert {
  height: 3px;
  min-width: 100%;
}
.summary-card:hover {
  border-color: #0965c2;
  cursor: pointer;
}

.card-danger {
  animation: blinkRed 1s linear infinite;
}
.card-danger .data,
.card-danger .card-label {
  color: whitesmoke !important;
}

.card-warning {
  animation: blinkGold 2s linear infinite;
}
.card-warning .data,
.card-warning .card-label {
  color: #333 !important;
}

.card-alert {
  animation: blinkGreen 3s linear infinite;
}
.card-alert .data,
.card-alert .card-label {
  color: #333 !important;
}

@keyframes blinkRed {
  50% {
    background-color: darkred;
    color: whitesmoke !important;
  }
}
@keyframes blinkGold {
  50% {
    background-color: goldenrod;
  }
}
@keyframes blinkGreen {
  50% {
    background-color: #0965c2;
  }
}
.action-tile {
  transition: all 0.25s linear;
}
.action-tile:hover {
  border-color: #0965c2;
  background-color: #0965c2;
}
.action-tile:hover * {
  color: #ccc !important;
}

.tile-active {
  border-color: #0965c2;
  background-color: #0965c2;
}
.tile-active * {
  color: #ccc !important;
}

.primary-nav {
  z-index: 100;
  position: sticky;
  top: 0;
  background-color: white !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 50px;
  padding-right: 4vw;
  padding-left: 4vw;
}
.primary-nav .nav-logo {
  background-color: whitesmoke !important;
  display: inline;
  padding-left: 10px;
}
.primary-nav .nav-links {
  width: 80vw;
}
.primary-nav .nav-links a {
  padding: 9px;
  margin-right: 5px;
  color: #555;
}
.primary-nav .nav-links a .bi {
  margin-right: 0px;
}
.primary-nav .nav-links a:hover {
  background-color: #ddd;
  color: #0965c2 !important;
  font-weight: bold;
}

.active-nav {
  background-color: #ddd;
  color: #0965c2 !important;
  font-weight: bold;
  border-radius: 5px;
}

@media screen and (min-width: 600px) {
  .primary-nav {
    padding-right: 9vw;
    padding-left: 9vw;
  }
  .primary-nav .nav-links {
    width: 70vw;
  }
  .primary-nav .nav-links a {
    padding: 17px 17px 14px 17px;
    color: #555;
    margin-right: 0px;
  }
  .primary-nav .nav-links a .bi {
    margin-right: 5px;
  }
  .active-nav {
    background-color: #ddd;
    color: #0965c2 !important;
    font-weight: bold;
    border-radius: 0px;
  }
}
* {
  margin: 0;
  box-sizing: content-box;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif, calibri;
}

.delete-item:hover {
  color: red;
  cursor: pointer;
}

.print-size-58 {
  width: 170px !important;
}

.print-size-80 {
  width: 200px !important;
}

.site-page-header {
  border: 1px solid rgb(235, 237, 240);
}

.text-green {
  color: #0965c2;
}

.bg-green {
  background-color: #0965c2 !important;
}

.titlebar {
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
  -webkit-app-region: drag;
}/*# sourceMappingURL=styles.css.map */